import { FC } from 'react';
import Box from '@mui/material/Box';
import { NomineeTable } from 'components/Nominee/NomineeTable';
import { useMediaQuery, Typography, type Theme } from '@mui/material';
import { useGetCurrentVotingStatistics } from 'api/hooks/statistics';
import { CallToAction, CounterInfo } from 'components/CTA';
import { Highlight } from 'components/Layout/Highlight';
import { ReactComponent as NominateIcon } from 'assets/A4BEE-volounteer.svg';
import { ReactComponent as VoteIcon } from 'assets/A4BEE-how-to-vote.svg';
import { useUserData } from 'providers/user';

const makeVotePageCounters = (
  thisMonthNominations: number | undefined,
  thisMonthVotes: number | undefined,
  numOfEmployees: number | undefined,
  isSmall: boolean,
): CounterInfo[] => [
  {
    Icon: NominateIcon,
    number: thisMonthNominations ?? null,
    tooltip: {
      position: isSmall ? 'relative' : { top: '100px', right: '-125px' },
      arrow: 'left',
      content: 'Nominations!',
    },
  },
  {
    Icon: VoteIcon,
    number: thisMonthVotes ?? null,
    total: numOfEmployees ? numOfEmployees * 3 : null,
    tooltip: {
      position: isSmall ? 'relative' : { top: '100px', right: '-75px' },
      arrow: 'left',
      content: 'Votes!',
    },
  },
];

export const VotePage: FC = (): React.ReactElement => {
  const { data: thisMonthStats } = useGetCurrentVotingStatistics();
  const { users } = useUserData();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  return (
    <Box
      sx={{
        pt: 8,
        pb: 6,
      }}>
      <CallToAction
        columns='minmax(0, 5fr) minmax(0, 5fr)'
        gap='2em 100px'
        title={
          <Typography variant={isSmall ? 'headlineMedium' : 'displayMedium'} fontWeight='700'>
            <Highlight text='Time to vote!' /> <br />
            Congratulations to all nominees!
          </Typography>
        }
        counters={makeVotePageCounters(
          thisMonthStats?.num_of_nominations,
          thisMonthStats?.num_of_votes,
          users?.length,
          isSmall,
        )}
        points={['You have a total of three votes', "You can't vote for the same person twice"]}
      />
      <NomineeTable isVote />
    </Box>
  );
};
